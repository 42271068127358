<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card
          width="100%"
          outlined
          tile
          id="tabsCardGerenciadorasYNivelesAtencion"
        >
          <!-- Tabs -->

          <v-tabs
            id="tabsGerenciadorasYNivAtencion"
            v-model="tab"
            active-class="active"
            center-active
            height="2.5rem"
            hide-slider
          >
            <v-tab href="#gerenciadoras"
              >Gerenciadoras
              <v-icon
                small
                class="primary--text"
                v-if="tab != 'gerenciadoras'"
                >{{ openTabIcon }}</v-icon
              >
            </v-tab>
            <v-tab href="#niveles-atencion"
              >Nivel de atención
              <v-icon
                small
                class="primary--text"
                v-if="tab != 'niveles-atencion'"
                >{{ openTabIcon }}</v-icon
              >
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <!-- Niveles de atención -->
            <v-tab-item value="niveles-atencion">
              <v-container class="d-flex align-center justify-center">
                <v-card flat width="35rem">
                  <v-data-table
                    class="elevation-2"
                    :headers="nivelesAtencionHeaders"
                    :items="itemsNivelesAtencion"
                    item-key="tdId"
                    :search="searchNivelesAtencion"
                    :loading="isLoading"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="12" md="6" align-self="center">
                            <v-text-field
                              v-model="searchNivelesAtencion"
                              :append-icon="searchIcon"
                              label="Buscar"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6" align="end" v-if="canCreate">
                            <v-btn
                              color="primary"
                              @click="openModalNivelAtencion()"
                              class="to-right"
                            >
                              Nuevo
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip left v-if="canEdit">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="20"
                            v-on="on"
                            @click="openModalNivelAtencion(item)"
                          >
                            {{ editIcon }}
                          </v-icon>
                        </template>
                        <span>Editar nivel de atención</span>
                      </v-tooltip>
                      <v-tooltip left v-if="canDelete">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="20"
                            v-on="on"
                            @click="deleteNivAtencion(item)"
                          >
                            {{ deleteIcon }}
                          </v-icon>
                        </template>
                        <span>Eliminar nivel de atención</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card></v-container
              >
            </v-tab-item>

            <!-- Gerenciadoras -->
            <v-tab-item value="gerenciadoras">
              <v-container class="d-flex align-center justify-center"
                ><v-card flat width="60rem">
                  <v-data-table
                    :headers="gerenciadorasheaders"
                    :items="itemsGerenciadoras"
                    class="elevation-1"
                    :search="searchGerenciadoras"
                    :loading="isLoading"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="10">
                            <v-text-field
                              v-model="searchGerenciadoras"
                              :append-icon="searchIcon"
                              label="Buscar"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" align="end" v-if="canCreate">
                            <v-btn
                              color="primary"
                              @click="openModalGerenciadora()"
                              class="to-right"
                            >
                              Nuevo
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip left v-if="canEdit">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="20"
                            v-on="on"
                            @click="openModalGerenciadora(item)"
                          >
                            {{ editIcon }}
                          </v-icon>
                        </template>
                        <span>Editar gerenciadora</span>
                      </v-tooltip>
                      <v-tooltip left v-if="canDelete">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="20"
                            v-on="on"
                            @click="deleteGerenciadoras(item)"
                          >
                            {{ deleteIcon }}
                          </v-icon>
                        </template>
                        <span>Eliminar gerenciadora</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card></v-container
              ></v-tab-item
            >
          </v-tabs-items>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <!-- Editar Nivel de atención -->
    <v-dialog
      v-if="openModalEditNivelAtencion"
      v-model="openModalEditNivelAtencion"
      @keydown.esc="closeAndReload"
      max-width="30rem"
      persistent
    >
      <EditNivelAtencion
        :nivelAtencionParams="nivelAtencionParams"
        @closeAndReload="closeAndReload"
      ></EditNivelAtencion>
    </v-dialog>

    <!-- eliminar Nivel de atención -->
    <DeleteDialog
      :titleProp="titleDeleteNivelAtencion"
      :openDelete.sync="showDeleteModalNivelAtencion"
      :maxWidth="'28%'"
      @onDeleteItem="confirmDeleteNivelAtencion()"
    />

    <!-- Editar Gerenciadoras -->
    <v-dialog
      v-if="openModalEditGerenciadoras"
      v-model="openModalEditGerenciadoras"
      @keydown.esc="closeAndReloadGerenciadora"
      max-width="30rem"
      persistent
    >
      <EditGerenciadoras
        :gerenciadoraParams="gerenciadoraParams"
        @closeAndReloadGerenciadora="closeAndReloadGerenciadora"
      ></EditGerenciadoras>
    </v-dialog>
    <!-- Eliminar gerenciadora -->
    <DeleteDialog
      :titleProp="titleDeleteGerenciadora"
      :openDelete.sync="showDeleteModalGerenciadora"
      :maxWidth="'25%'"
      @onDeleteItem="confirmDeleteGerenciadora()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import EditGerenciadoras from "@/components/modules/afiliaciones/afiliados/configuracion/EditGerenciadoras.vue";
import EditNivelAtencion from "@/components/modules/afiliaciones/afiliados/configuracion/EditNivelAtencion.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "GerenciadorasYNivelesAtencion",
  components: {
    PageHeader,
    EditGerenciadoras,
    DeleteDialog,
    EditNivelAtencion,
    GoBackBtn,
    Ayuda
  },

  data() {
    return {
      title: enums.titles.GERENCIADORAS_Y_NIV_ATENCION,
      openTabIcon: enums.icons.DOWN_ARROW,
      deleteIcon: enums.icons.DELETE,
      searchIcon: enums.icons.SEARCH,
      routeToGo: "TablasAfiliados",
      showExpand: false,
      showHelp: false,
      showIcon: true,
      optionCode: enums.webSiteOptions.GERENCIADORAS_NIVELES_ATENCION,
      searchGerenciadoras: null,
      searchNivelesAtencion: null,
      isLoading: false,
      tab: null,
      editIcon: enums.icons.EDIT,
      titleDeleteNivelAtencion: "¿Eliminar nivel de atención?",
      titleDeleteGerenciadora: "¿Eliminar gerenciadora?",
      idToDelete: null,
      showDeleteModalNivelAtencion: false,
      showDeleteModalGerenciadora: false,
      itemsNivelesAtencion: [],
      itemsGerenciadoras: [],
      nivelesAtencionHeaders: [
        {
          text: "Nombre de nivel de atención",
          align: "start",
          sortable: true,
          value: "nivelNombre"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      gerenciadorasheaders: [
        {
          text: "Nombre de gerenciadora",
          value: "gerNom",
          sortable: true
        },

        {
          text: "Código",
          sortable: false,
          value: "gerCod"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      allowedActions: null,
      canEdit: false,
      canDelete: false,
      canCreate: false,
      openModalEditGerenciadoras: false,
      openModalEditNivelAtencion: false
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadGerenciadoras();
    this.loadNivelAtencion();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getNivelesDeAtencion: "configAfiliaciones/getNivelesDeAtencion",
      deleteNivelAtencion: "configAfiliaciones/deleteNivelAtencion",
      getGerenciadoras: "configAfiliaciones/getGerenciadoras",
      deleteGerenciadora: "configAfiliaciones/deleteGerenciadora",
      setAlert: "user/setAlert"
    }),

    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVA_GERENCIADORA_Y_NIVEL_ATENCION:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_GERENCIADORA_Y_NIVEL_ATENCION:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_GERENCIADORA_Y_NIVEL_ATENCION:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },

    async loadGerenciadoras() {
      this.isLoading = true;
      const data = await this.getGerenciadoras();
      this.itemsGerenciadoras = data;
      this.isLoading = false;
    },
    deleteGerenciadoras(id) {
      this.showDeleteModalGerenciadora = true;
      this.idToDelete = id;
    },
    async confirmDeleteGerenciadora() {
      const response = await this.deleteGerenciadora(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModalGerenciadora = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadGerenciadoras();
      }
    },
    openModalGerenciadora(item) {
      this.openModalEditGerenciadoras = true;
      this.gerenciadoraParams = item;
    },
    closeAndReloadGerenciadora() {
      this.openModalEditGerenciadoras = false;
      this.loadGerenciadoras();
    },

    async loadNivelAtencion() {
      this.isLoading = true;
      const data = await this.getNivelesDeAtencion();
      this.itemsNivelesAtencion = data;
      this.isLoading = false;
    },
    deleteNivAtencion(id) {
      this.showDeleteModalNivelAtencion = true;
      this.idToDelete = id;
    },
    async confirmDeleteNivelAtencion() {
      const response = await this.deleteNivelAtencion(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModalNivelAtencion = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadNivelAtencion();
      }
    },
    openModalNivelAtencion(item) {
      this.openModalEditNivelAtencion = true;
      this.nivelAtencionParams = item;
    },
    closeAndReload() {
      this.openModalEditNivelAtencion = false;
      this.loadNivelAtencion();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
.active {
  color: #f8f9fa !important;
  background-color: #195472 !important;
}
#tabsCardGerenciadorasYNivelesAtencion .active {
  border-radius: 0.2rem !important;
}
.scroll {
  overflow-y: scroll;
}
.tabsGerenciadorasYNivAtencion {
  border-radius: 0.2rem !important;
}
</style>
