<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveNivelAtencion()"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="Nombre de nivel de atención"
                v-model="nombreNivelAtencion"
                autocomplete="off"
                :rules="
                  rules.required.concat(
                    [rules.maxLength(nombreNivelAtencion, 50)],
                    rules.requiredTrim(nombreNivelAtencion)
                  )
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditNivelAtencion",
  props: {
    nivelAtencionParams: { type: Object }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_NIVEL_ATENCION,
    newTitle: enums.titles.NUEVO_NIVEL_ATENCION,
    rules: rules,
    nombreNivelAtencion: null
  }),
  created() {
    if (this.nivelAtencionParams) {
      this.setNivelAtencion(this.nivelAtencionParams);
    } else {
      this.newNivelAtencion();
    }
  },
  methods: {
    ...mapActions({
      saveNivelesDeAtencion: "configAfiliaciones/saveNivelesDeAtencion",
      setAlert: "user/setAlert"
    }),
    closeModal() {
      this.$emit("closeAndReload");
    },
    setNivelAtencion(obj) {
      this.nivelId = obj.nivelId;
      this.nombreNivelAtencion = obj.nivelNombre;
    },
    async newNivelAtencion() {
      this.formEditTitle = this.newTitle;
    },
    async saveNivelAtencion() {
      const data = {
        nivelId: this.nivelAtencionParams
          ? this.nivelAtencionParams.nivelId
          : null,
        nivelNombre: this.nombreNivelAtencion
      };

      const res = await this.saveNivelesDeAtencion(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    }
  }
};
</script>
