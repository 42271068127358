<template>
  <v-card>
    <v-container>
      <v-card-title class="pt-2">
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveGerenciadora()"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="Nombre gerenciadora"
                v-model.trim="nombreGerenciadora"
                autocomplete="off"
                :rules="
                  rules.required.concat([
                    rules.maxLength(nombreGerenciadora, 50),
                    rules.requiredTrim(nombreGerenciadora)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model.trim="codigoGer"
                label="Código de gerenciadora"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(codigoGer, 50),
                    rules.requiredTrim(codigoGer)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditGerenciadoras",
  props: {
    gerenciadoraParams: { type: Object }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_GERENCIADORA,
    newTitle: enums.titles.NUEVA_GERENCIADORA,
    rules: rules,
    nombreGerenciadora: "",
    codigoGer: ""
  }),
  mounted() {
    if (this.gerenciadoraParams) {
      this.setGerenciadoras(this.gerenciadoraParams);
    } else {
      this.newGerenciadora();
    }
  },
  methods: {
    ...mapActions({
      saveGerenciadoras: "configAfiliaciones/saveGerenciadoras",
      setAlert: "user/setAlert"
    }),
    closeModal() {
      this.$emit("closeAndReloadGerenciadora");
    },
    setGerenciadoras(obj) {
      this.gerId = obj.gerId;
      this.nombreGerenciadora = obj.gerNom;
      this.codigoGer = obj.gerCod == "" ? null : obj.gerCod;
    },
    async newGerenciadora() {
      this.formEditTitle = this.newTitle;
    },
    async saveGerenciadora() {
      const data = {
        gerId: this.gerenciadoraParams?.gerId,
        gerCod: this.codigoGer,
        gerNom: this.nombreGerenciadora
      };

      const res = await this.saveGerenciadoras(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    }
  }
};
</script>
